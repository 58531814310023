import { Link } from "react-router-dom";

import "./Home.css";

function Home() {
  return (
    <div className="home">
      <img
        className="home__hero"
        src="/main_header2.png"
        alt="Characters from Slime in a Trench Coat"
      />
      <div>
        <iframe
          className="home__video"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/2o9HJyKMJVk?si=GuV_iXC-jiq51ZAm"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>

      <div className="home__story">
        <p>
          You are a food delivery person who’s been framed for murder and it’s
          up to you to clear your name. Your only companion?
        </p>
        <p>A talking slime in a trench coat.</p>
      </div>
      <img
        className="home__banner"
        src="/banner3.png"
        alt="Light shining down on a mossy rock in a forest clearing"
      />
      <div className="home__wishlistCTA">
        <a href="https://store.steampowered.com/app/3217720/Slime_in_a_Trench_Coat_A_Cooking_Mystery/">
          <button className="home__button">
            Add Slime in a Trench Coat
            <br />
            to your Wishlist on Steam
          </button>
        </a>
        <iframe
          className="home__steamWidget"
          src="https://store.steampowered.com/widget/3217720/"
          frameborder="0"
          width="646"
          height="190"
          title="Steam Page"
        ></iframe>
      </div>
      <div>
        <img
          className="home__kickstarterLogo"
          src="/d3rwhjkg-kickstarter-logo-white.avif"
          alt="Kickstarter Logo"
        />
      </div>
      <div>
        <a href="https://www.kickstarter.com/projects/kittenbeanstudios/slime-in-a-trench-coat-a-cooking-mystery">
          <button className="home__button">Support us on Kickstarter!</button>
        </a>
      </div>
      <div className="home__presskitCTA">
        <Link to="presskit">
          <button className="home__button">Go to Presskit</button>
        </Link>
      </div>
    </div>
  );
}

export default Home;
