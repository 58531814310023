import React from "react";
import { Link } from "react-router-dom";

import "./Presskit.css";

function Presskit() {
  return (
    <div className="presskit" id="top">
      <div className="presskit__section">
        Presskit
        <div className="presskit__link">
          <a href="https://drive.google.com/drive/folders/1b5CCb9F1i9lLyaIpx2kPRrBbc54qxpr9?usp=drive_link">
            Presskit in Google Drive
          </a>
        </div>
      </div>
      <div className="presskit__section">
        Factsheet
        <div className="presskit__card">
          Game Name: Slime in a Trench Coat: A Cooking Mystery
          <br />
          Developer: Kittenbean Studios
          <br />
          Publisher: Self-published
          <br />
          Release Date: TBA
          <br />
          Platforms: Steam (Windows, MacOS)
        </div>
      </div>
      <div className="presskit__section">
        Links/Socials
        <div className="presskit__card">
          Discord:{" "}
          <a href="https://discord.gg/SQNTZtHkTU">
            https://discord.gg/SQNTZtHkTU
          </a>
          <br />
          TikTok:{" "}
          <a href="https://www.tiktok.com/@kittenbean.studio">
            https://www.tiktok.com/@kittenbean.studio
          </a>
          <br />
          Website:{" "}
          <a href="https://slimeinatrenchcoat.com">
            https://slimeinatrenchcoat.com
          </a>
          <br />
          Contact:{" "}
          <a href="support@kittenbeanstudios.com">
            support@kittenbeanstudios.com
          </a>
        </div>
      </div>
      <div className="presskit__section">
        About the Game
        <div className="presskit__card">
          Slime in a Trench Coat: A Cooking Mystery is a narrative-driven 2D
          mystery game set in a charming world filled with action, cooking, and
          intrigue. You’ll solve puzzles, fight monsters, and unlock new recipes
          as you follow Jin and Jel on their journey to solve the mystery of
          Juniper’s disappearance.
          <br />
          <br />
          Slime in a Trench Coat: A Cooking Mystery is heavily inspired by cute,
          DS games from the early 2000s like Professor Layton, Ace Attorney, and
          Cooking Mama as well as the nostalgic aesthetic of Maplestory.
        </div>
      </div>
      <div className="presskit__section">
        About the Developer
        <div className="presskit__card">
          Kittenbean Studios is an indie game studio run entirely by two
          sisters. The studio’s goal is to make the cute, fun, and cozy games
          they've always craved.
          <br />
          <br />
          This is their first PC offering and the second game they’re releasing
          as Kittenbean Studios.
        </div>
      </div>
      <div className="presskit__section">
        Gameplay Features
        <div className="presskit__card">
          <ul>
            <li>
              Gather evidence by talking to NPCs to uncover the mystery behind
              Juniper’s disappearance
            </li>
            <li>
              Interview suspicious characters and find the lies and
              inconsistencies in their claims during interrogation minigames
            </li>
            <li>
              Solve puzzles, crack codes, and discover hidden areas and
              confidential files
            </li>
            <li>
              Don various disguises and outfits while on the run from the
              authorities
            </li>
            <li>
              Play cooking minigames to create consumable dishes with special
              properties to enhance your abilities
            </li>
            <li>
              Equip chef's knives, rolling pins, balloon whisks, and more
              kitchen utensils to unlock new ways of cooking and combat
            </li>
            <li>
              Attack and dodge slimes, mushrooms, golems, and other classic
              monsters in 2D side-scrolling perspective
            </li>
          </ul>
        </div>
      </div>
      <div className="presskit__section">
        Screenshots
        <div className="presskit__images">
          <img
            className="presskit__image"
            src="/screenshot1.png"
            alt="Screenshot 1"
          />
          <img
            className="presskit__image"
            src="/screenshot3.png"
            alt="Screenshot 3"
          />
          <img
            className="presskit__image"
            src="/screenshot4.png"
            alt="Screenshot 4"
          />
          <img
            className="presskit__image"
            src="/screenshot5.png"
            alt="Screenshot 5"
          />
          <img
            className="presskit__image"
            src="/screenshot2.png"
            alt="Screenshot 2"
          />
        </div>
      </div>
      <div className="presskit__section">
        Key Art
        <div className="presskit__images">
          <img
            className="presskit__image"
            src="/keyart_1.png"
            alt="Key Art 1"
          />
          <img
            className="presskit__image"
            src="/keyart_2.png"
            alt="Key Art 2"
          />
          <img
            className="presskit__image"
            src="/keyart_1logoless.png"
            alt="Key Art 1 without logo"
          />
          <img
            className="presskit__image"
            src="/keyart_2logoless.png"
            alt="Key Art 2 without logo"
          />
        </div>
      </div>
      <div className="presskit__section">
        Logo
        <div className="presskit__images">
          <img className="presskit__logo" src="/logo_full.png" alt="Logo 1" />
          <img
            className="presskit__logo"
            src="/logo_nosilhouette.png"
            alt="Logo 2"
          />
        </div>
      </div>
      <div>
        <Link to="/">
          <button className="presskit_button">Back to Home</button>
        </Link>
      </div>
    </div>
  );
}

export default Presskit;
